
<script>
export default {
  name: "ResizeAuto",
  methods: {
    resize(event) {
      if (!event.target) return;
      event.target.style.height = "auto";
      event.target.style.height = `${
        event.target.scrollHeight ? event.target.scrollHeight + 2 : 30
      }px`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      try {
        this.$el.setAttribute(
          "style",
          "height",
          `${this.$el ? this.$el.scrollHeight : 30}px`
        );
      } catch (error) {
        console.log(error);
      }
    });
  },
  render() {
    return this.$scopedSlots.default({
      resize: this.resize,
    });
  },
};
</script>

<style scoped>
.textarea {
  width: 100%;
  /* min-height: 50px; */
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;
}
</style>